import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store/index'
import { isWeixin, getQueryVariable} from "@/utils/index.js";
import {BASE_URL, APPID} from '@/utils/config.js'
// REDIRECT_URL
// 解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  // store.commit('changeTabActive', to.path)
      // next();
  var isWeixins = isWeixin()
  if(localStorage.getItem('token') && localStorage.getItem('openId')){
    if(to.path != '/login'){
      store.commit('changeTabActive', to.path)
      next();
    } else {
      next('/');
    }
  } else {
    if(isWeixins){
      if(getQueryVariable("wechatfrom") == 'wechat'){
        store.commit('changeTabActive', to.path)
        next();
      } else {
        var nextUrl = document.location.href
        var origin   = window.location.origin
        var redirectH5Uri = origin+'/#/login?wechatfrom=wechat&next_url='+encodeURIComponent(nextUrl)
        var redirectUri = BASE_URL+'/api/initWechatUser?h5_redirect_url='+encodeURIComponent(redirectH5Uri)
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=snsapi_base&state=STATE`
      }
    } else {
      store.commit('changeTabActive', to.path)
      next();
    }
  }
})

export default router
