
const routes = [
  {
    path: "/",
    name: "Layout",
    component: () => import("../pages/home/home.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        meta: {
          title: "首页",
        },
        component: () => import("../pages/index/index.vue"),
      },
      {
        path: "/payment",
        name: "payment",
        meta: {
          title: "付款",
        },
        component: () => import("../pages/payment/payment.vue"),
      },
      {
        path: "/my",
        name: "my",
        meta: {
          title: "我的",
        },
        component: () => import("../pages/my/my.vue"),
      }
    ]
  },
  {
    path: "/lawyer",
    name: "lawyer",
    meta: {
      title: "律师函",
    },
    component: () => import("../pages/lawyer/lawyer.vue"),
  },
  {
    path: "/stages",
    name: "stages",
    meta: {
      title: "账单分期",
    },
    component: () => import("../pages/stages/stages.vue"),
  },
  {
    path: "/quick",
    name: "quick",
    meta: {
      title: "账单分期",
    },
    component: () => import("../pages/stages/quick.vue"),
  },
  {
    path: '/myBankCard',
    component: () => import("../pages/my/my-bank-card.vue"),
  },
  {
    path: '/myOrder',
    component: () => import("../pages/my/my-order.vue"),
  },
  {
    path: '/paymentSchedule',
    component: () => import("../pages/my/payment-schedule.vue"),
  },
  {
    path: '/schedulePayment',
    name: 'schedulePayment',
    component: () => import("../pages/my/schedule-payment.vue"),
  },
  {
    path: '/paymentHistory',
    name: 'paymentHistory',
    component: () => import("../pages/my/payment-history.vue"),
  },
  {
    path:'/aboutUsWe',
    component: () => import("../pages/my/my-about-us.vue")
  },
  {
    path:'/feedback',
    component: () => import("../pages/my/feedback.vue")
  },
  {
    path:'/changeBankCard',
    component: () => import("../pages/my/change-bank-card.vue")
  },
  {
    path: '/referral',
    name: 'referral',
    component: () => import("../pages/referral/referral-code.vue"),
  },
  {
    path: '/referralQuick',
    name: 'referralQuick',
    component: () => import("../pages/referral/referral-code-quick.vue"),
  },
  {
    path: '/AlipayPayment',
    component: () => import("../pages/my/ali-pay.vue"),
  },
  {
    path: '/login',
    name:'login',
    component: () => import("../pages/login/login.vue"),
  },
  {
    path: '*',
    component: () => import("../pages/page404/index.vue"), //404页面
  },
]

export default routes
