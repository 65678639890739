// import CryptoJS from 'crypto-js'
// //随机生成指定数量的16进制key
// export const generatekey = (num) => {
//   let library = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
//   let key = ''
//   for (var i = 0; i < num; i++) {
//     let randomPoz = Math.floor(Math.random() * library.length)
//     key += library.substring(randomPoz, randomPoz + 1)
//   }
//   return key
// }

export function isWeixin() {
  var ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}

export function getQueryVariable(variable) {
  var num = window.location.hash.indexOf("?");
  var query = window.location.hash.substring(num + 1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}